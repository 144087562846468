.movie-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .movie-card img {
    width: 100%;
    max-width: 300px;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .movie-card h3 {
    margin: 8px 0;
  }
  
  .movie-card p {
    margin: 4px 0;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  
  .action-buttons button {
    margin: 0 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .action-buttons button:hover {
    background-color: #0056b3;
  }