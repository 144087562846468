.main{
  display: flex;
  flex-direction: column;

  background-color: #f0f0f0;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  
  .title {
    margin: 0;
  }
  
  .search-box {
    display: flex;
    align-items: center;
  }
  
  .search-box input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-button {
    margin-left: 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .action-buttons {
    display: flex;
    align-items: center;
  }
  
  .saved-button,
  .watch-later-button {
    margin-left: 8px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .saved-button:hover,
  .watch-later-button:hover {
    background-color: #0056b3;
  }
  .filters{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    padding: 20px 10px;
  }